<template>
  <UiScrollWrapper
    v-if="props.breadcrumbs.length > 1"
    ref="uiScrollWrapper"
    :with-icon="false"
    :scroll-to-right="true"
  >
    <ol
      class="mb-3 mt-4 flex text-sm"
      data-el="app-breadcrumbs"
    >
      <li
        v-for="(item, index) in props.breadcrumbs"
        :key="index"
        class="group inline-block after:mx-1 after:content-['>'] last:after:content-['']"
      >
        <component
          :is="getComponent(item)"
          :to="item.link"
          class="whitespace-nowrap text-primary transition-colors group-last-of-type:text-blue-100 md:hover:text-blue-100"
          :class="{
            'cursor-default': props.breadcrumbs.length === index + 1,
          }"
        >
          <span>{{ item.title }}</span>
        </component>
      </li>
    </ol>
  </UiScrollWrapper>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, resolveComponent, watch } from 'vue'
import UiScrollWrapper from '@ui/components/helpers/UiScrollWrapper/UiScrollWrapper.vue'
import type { AppBreadcrumb, AppBreadcrumbsProps } from './AppBreadcrumbs.types'

const props = defineProps<AppBreadcrumbsProps>()

function getComponent(item: AppBreadcrumb) {
  return item.link ? resolveComponent('NuxtLink') : 'span'
}

const uiScrollWrapper = ref<InstanceType<typeof UiScrollWrapper> | null>(null)
watch(
  () => props.breadcrumbs,
  () => nextTick(() => uiScrollWrapper?.value?.scrollToRightFun()),
)

/**
 * object prepared for schema.org breadcrumbs' itemListElement
 * https://unhead.unjs.io/schema-org/recipes/breadcrumbs
 */
const breadcrumbsList = computed(() => {
  const breadcrumbsItems = props.breadcrumbs?.map((item) => {
    return {
      name: item?.title || '',
      item: item?.link || '',
    }
  })

  return breadcrumbsItems.length > 1 ? breadcrumbsItems : []
})

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: () => breadcrumbsList.value,
  }),
])
</script>
